/*------------------------------------*\
  #GLOBALS
\*------------------------------------*/

@import "../../setup/import";

/*------------------------------------*\
  #PAGE SPECIFIC STYLES
\*------------------------------------*/

.notfound {
	margin-top: -$main-offset;
}

.notfound--search-open {
    background: none;
}

@include media-query(small-up) {
    .notfound--search-open {
        padding-top: rem-calc(164);
    }
}
